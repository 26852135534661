@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;600&display=swap');

* {
  font-family:'Roboto Condensed', sans-serif;
}

input[type='checkbox'] {
  accent-color: #34225c;
}

.box-label{
  padding:2px;
  margin:2px;
  color:#34225c;
  word-wrap: break-word;
  font-weight: 400;
}

.result-label{
  visibility: hidden;
  position: relative;
  font-size: 18px;
  font-weight: 400;
  left: 45px;
  top: 25px;
}


.title{
  position: fixed;
  left: 50px;
  top:75px;
}

.description{
  position: fixed;
  left: 55px;
  top: 125px;
  font-weight: 300;
}

.button-container{
  height: 100px;
  width: 200px;
  position:fixed;
  left: 1200px;
  top: 300px;
}

.form-button{
  width: 150px;
  height: 45px;
  margin: 2px;
  padding: 5px;
  background-color: #34225c;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
  border: 2px solid #34225c;
}

.form-button {
  transition-duration: 0.2s;
}

.form-button:hover {
  background-color: #a999cc;
  color: white;
}

.checkbox-background{
  height: 230px;
  width: 1100px;
  columns: 100px 7;
  background-color: #C9E1D9;
  border-radius: 10px;
  position:fixed;
  padding: 10px;
  left: 50px;
  top: 250px;
  overflow: auto;
}


